module.exports = {
  name: "Maya Seguros y Mas",
  shortName: "Maya Seguros y Mas",
  description:
    "At Maya Seguros y Mas we help you fill out all kinds of forms and applications: Divorce Application, ITIN Renewal, Citizenship Application, DACA Renewal, Permits to travel to Mexico with minors",
  descriptionEs:
    "En Maya Seguros y Mas te ayudamos a llenar todo tipo de formas y aplicaciones: Aplicación de Divorcio, Renovación de ITIN, Aplicación de Ciudadanía, Renovación de DACA, Permisos para viajar a México con menores.",
  url: "https://mayasegurosymas.com", // Domain of your site without prefix!
  prefix: "/",
  author: "",
  twitter: "",
  rocioEmail: "Rocio@mayasegurosymas.com",
  juanitaEmail: "Juanita@mayasegurosymas.com",
  contactPhone: "9726854813",
  contactPhoneDisplay: "(972) 685 - 4813",
  addressLine1: "429 W 12th Street",
  addressLine2: "Dallas, TX 75208",
};
