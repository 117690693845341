const dark_gold = "#d1b017"; //rgba(209, 176, 23, 1)

export default {
  gold: "#ffd700",
  dark_gold,
  pink: "#ff0083",
  link: dark_gold,
  icon: "#2f4353",
  companyName: "#2f4353",
  white: "#ffffff",
};
