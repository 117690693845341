import React from "react";
import styled from "styled-components";
import COLORS from "../data/colors";

const H1 = styled.h1`
  font-family: Cormorant Garamond;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
  font-size: 40px;
  line-height: 1;
  color: #454545 !important;
`;

const H2 = styled.h2`
  font-weight: 400;
  margin: 1 0 1em 0;
  display: block;
  font-size: 20px;
  color: #454545 !important;
`;

const Container = styled.div`
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  background-color: ${COLORS.dark_gold};
  width: 10vw;
  height: 2px;
  margin: 24px auto;
  display: block;

  @media (max-width: 850px) {
    width: 30%;
  }
`;

export const Title = ({
  title,
  subtitle,
  className,
  divider = true,
}: {
  title: string;
  subtitle?: string;
  className?: string;
  divider?: boolean;
}) => (
  <Container className={className}>
    <H1>{title}</H1>

    {divider && <Divider />}

    {!!subtitle && <H2 style={{ textAlign: "center" }}>{subtitle}</H2>}
  </Container>
);
