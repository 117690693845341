import React from "react";
import styled from "styled-components";
import SiteConfig from "../../siteConfig";
import { LANGUAGE, useStore } from "../context/store";

const StyledParagraph = styled.p`
  font-weight: 600;
  font-size: 12px;
  margin: 0 80px;
  line-height: 1.7;
  color: rgb(126 124 124);

  @media (max-width: 425px) {
    margin: 4vw;
  }
`;

export const Disclaimer = () => {
  const siteTitle = SiteConfig.name;

  const { language } = useStore();
  const isSpanish = language === LANGUAGE.es;

  return isSpanish ? (
    <StyledParagraph>
      Descargo de responsabilidad: {siteTitle} no es una oficina de abogados, no
      actúa como su abogado y no sustituye el asesoramiento de un abogado.{" "}
      {siteTitle} no proporciona ningún tipo de asesoramiento legal, y ninguna
      de la información proporcionada en este documento o en cualquier documento
      completo debe interpretarse como asesoramiento legal.
      {siteTitle} no puede brindar asesoramiento legal y solo puede brindar
      servicios de autoayuda en su dirección específica. Maya Seguros y Más
      puede ayudarlo a completar formularios legales cuando busca representarse
      a sí mismo en sus propios asuntos legales. Si necesita representación
      legal, está involucrado en un litigio o tiene problemas legales complejos
      que no puede manejar o resolver por su cuenta, debe buscar asesoramiento
      legal competente y/o contratar a un abogado. Nuestro servicio de
      preparación de documentación legal utiliza las respuestas que proporciona
      para completar formularios legales y completarlos en su nombre. Este
      servicio de preparación de documentos no tiene la intención de crear una
      relación abogado-cliente y, al utilizar nuestros servicios, no se creará
      ninguna relación abogado-cliente.
    </StyledParagraph>
  ) : (
    <StyledParagraph>
      Discalimer : {siteTitle} is not a law firm, does not act as your attorney,
      and is not a substitute for advice from an attorney. {siteTitle} does not
      provide any legal advice, and none of the information provided herein or
      on any completed document should be construed as legal advice. {siteTitle}{" "}
      cannot provide legal advice and can only provide self-help services at
      your specific direction. {siteTitle} can assist you in completing legal
      forms when you are seeking to represent yourself in your own legal
      matters. If you need legal representation, are involved in litigation, or
      have complex legal issues that cannot be handled or dealt with on your
      own, you should seek competent legal advice and/or hire an attorney. Our
      legal documentation preparation service uses the answers you provide to
      populate legal forms and complete them on your behalf. This document
      preparation service is not intended to create an attorney-client
      relationship, and by using our services, no attorney-client relationship
      will be created.
    </StyledParagraph>
  );
};
