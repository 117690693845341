import React from "react";
import COLORS from "./../data/colors";

export const Logo = () => {
  return (
    <svg
      viewBox="0 0 752 752"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "scale(2.5)", fill: "none" }}
    >
      <g>
        {[
          "m323 447.33c-1.2656 11.496-2.5352 22.988-3.8008 34.484 0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-50.789-87.672-31.578c0.51953 0.68359 1.0391 1.3672 1.5625 2.0508 4.6836-11.496 9.3633-22.988 14.047-34.484-0.51953 0.39453-1.0391 0.79297-1.5625 1.1875h52.902 85.547 21.352c2.0859 0 2.0859-3.2383 0-3.2383h-52.902-85.547-21.352c-0.78516 0-1.2852 0.50781-1.5625 1.1875-4.6836 11.496-9.3633 22.988-14.047 34.484-0.40625 0.99609 0.60156 2.0508 1.5625 2.0508h50.789 87.672 31.578c0.95703 0 1.5234-0.74219 1.6172-1.6172 1.2656-11.496 2.5352-22.988 3.8008-34.484 0.23047-2.0781-3.0078-2.0586-3.2344-0.003907z",
          "m604.93 480.2h-50.422-87.941-33.543c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172-1.2656-11.496-2.5352-22.988-3.8008-34.484-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h52.508 86.023 23.129c-0.51953-0.39453-1.0391-0.79297-1.5625-1.1875 4.6836 11.492 9.3633 22.988 14.047 34.484 0.77734 1.9062 3.9102 1.0742 3.1211-0.85938-4.6836-11.492-9.3633-22.988-14.047-34.484-0.27734-0.67969-0.77734-1.1875-1.5625-1.1875h-52.508-86.023-23.129c-0.80859 0-1.7148 0.73828-1.6172 1.6172 1.2656 11.496 2.5352 22.988 3.8008 34.484 0.097656 0.875 0.66406 1.6172 1.6172 1.6172h50.422 87.941 33.543c2.0898 0 2.0898-3.2344 0.003907-3.2344z",
          "m326.79 412.85c-1.2656 11.496-2.5312 22.988-3.7969 34.484l1.6172-1.6172h-58.297-74.484-3.6055c0.51953 0.68359 1.0391 1.3672 1.5625 2.0508 4.6836-11.496 9.3633-22.988 14.047-34.484-0.51953 0.39453-1.0391 0.79297-1.5625 1.1875h60.762 65.152 0.22266c2.0859 0 2.0859-3.2383 0-3.2383h-60.762-65.152-0.22266c-0.78516 0-1.2852 0.50781-1.5625 1.1875-4.6836 11.496-9.3633 22.988-14.047 34.484-0.40625 0.99609 0.60156 2.0508 1.5625 2.0508h58.297 74.484 3.6055c0.95703 0 1.5234-0.74219 1.6172-1.6172 1.2656-11.496 2.5312-22.988 3.7969-34.484 0.23047-2.0781-3.0078-2.0547-3.2344-0.003906z",
          "m567.47 445.71h-57.855-75.816-4.5781c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172-1.2656-11.496-2.5312-22.988-3.7969-34.484-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h60.309 67.137 0.55469c-0.51953-0.39453-1.0391-0.79297-1.5625-1.1875 4.6836 11.496 9.3633 22.988 14.047 34.484 0.77734 1.9062 3.9102 1.0742 3.1211-0.85937-4.6836-11.496-9.3633-22.988-14.047-34.484-0.27734-0.67969-0.77734-1.1875-1.5625-1.1875h-60.309-67.137-0.55469c-0.80859 0-1.7148 0.73828-1.6172 1.6172 1.2656 11.496 2.5312 22.988 3.7969 34.484 0.097657 0.875 0.66406 1.6172 1.6172 1.6172h57.855 75.816 4.5781c2.0898 0.003907 2.0898-3.2344 0.003906-3.2344z",
          "m330.59 378.36c-1.2656 11.496-2.5352 22.988-3.8008 34.484l1.6172-1.6172h-66.391-36.336c0.51953 0.68359 1.0391 1.3672 1.5625 2.0508 4.6836-11.496 9.3633-22.988 14.047-34.484-0.51953 0.39453-1.0391 0.79297-1.5625 1.1875h68.504 23.977c2.0859 0 2.0859-3.2383 0-3.2383h-68.504-23.977c-0.78516 0-1.2852 0.50781-1.5625 1.1875-4.6836 11.496-9.3633 22.988-14.047 34.484-0.40625 0.99609 0.60156 2.0508 1.5625 2.0508h66.391 36.336c0.95703 0 1.5234-0.74219 1.6172-1.6172 1.2656-11.496 2.5352-22.988 3.8008-34.484 0.23438-2.0742-3.0078-2.0547-3.2344-0.003907z",
          "m530.02 411.23h-65.992-38.598c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172-1.2656-11.496-2.5352-22.988-3.8008-34.484-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h68.227 26.121c-0.51953-0.39453-1.0391-0.79297-1.5625-1.1875 4.6836 11.492 9.3633 22.988 14.047 34.484 0.77734 1.9062 3.9102 1.0742 3.1211-0.85938-4.6836-11.492-9.3633-22.988-14.047-34.484-0.27734-0.67969-0.77734-1.1875-1.5625-1.1875h-68.227-26.121c-0.80859 0-1.7148 0.73828-1.6172 1.6172 1.2656 11.496 2.5352 22.988 3.8008 34.484 0.097656 0.875 0.66406 1.6172 1.6172 1.6172h65.992 38.598c2.0938 0 2.0938-3.2344 0.003906-3.2344z",
          "m334.39 343.88c-1.2539 11.496-2.5312 22.988-3.7969 34.484 0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-66.84-2.207c0.51953 0.68359 1.0391 1.3672 1.5625 2.0508 4.6836-11.496 9.3633-22.988 14.047-34.484-0.51953 0.39453-1.0391 0.79297-1.5625 1.1875h58.801c2.0859 0 2.0859-3.2383 0-3.2383h-58.801c-0.78516 0-1.2852 0.50781-1.5625 1.1875-4.6836 11.496-9.3633 22.988-14.047 34.484-0.40625 0.99609 0.60156 2.0508 1.5625 2.0508h66.84 2.207c0.95703 0 1.5234-0.74219 1.6172-1.6172 1.2656-11.496 2.543-22.988 3.7969-34.484 0.23047-2.0781-3.0078-2.0586-3.2344-0.003907z",
          "m492.58 376.75h-67.469-3.4922l1.6172 1.6172c-1.2656-11.496-2.543-22.988-3.7969-34.484-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h60.711c-0.51953-0.39453-1.0391-0.79297-1.5625-1.1875 4.6836 11.496 9.3633 22.988 14.047 34.484 0.77734 1.9062 3.9102 1.0742 3.1211-0.85938-4.6836-11.496-9.3633-22.988-14.047-34.484-0.27734-0.67969-0.77734-1.1875-1.5625-1.1875h-60.711c-0.80859 0-1.7148 0.73828-1.6172 1.6172 1.2539 11.496 2.5312 22.988 3.7969 34.484 0.097656 0.875 0.66406 1.6172 1.6172 1.6172h67.469 3.4922c2.0938 0.003906 2.0938-3.2344 0.003906-3.2344z",
          "m338.13 309.95c-1.2461 11.312-2.4961 22.621-3.7422 33.934 0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-35.168c0.51953 0.68359 1.0391 1.3672 1.5625 2.0508 4.6094-11.312 9.2148-22.621 13.824-33.934-0.51953 0.39453-1.0391 0.79297-1.5625 1.1875h25.086c2.0859 0 2.0859-3.2383 0-3.2383h-25.086c-0.78516 0-1.2852 0.50781-1.5625 1.1875-4.6094 11.312-9.2148 22.621-13.824 33.934-0.40625 0.99609 0.60156 2.0508 1.5625 2.0508h35.168c0.95703 0 1.5234-0.74219 1.6172-1.6172 1.2461-11.312 2.4961-22.621 3.7422-33.934 0.23437-2.0781-3.0078-2.0547-3.2344-0.003906z",
          "m454.91 342.26h-37.078c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172-1.2461-11.312-2.4961-22.621-3.7422-33.934-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h26.996c-0.51953-0.39453-1.0391-0.79297-1.5625-1.1875 4.6094 11.312 9.2148 22.621 13.824 33.934 0.77734 1.9062 3.9102 1.0742 3.1211-0.85938-4.6094-11.312-9.2148-22.621-13.824-33.934-0.27734-0.67969-0.77734-1.1875-1.5625-1.1875h-26.996c-0.80859 0-1.7148 0.73828-1.6172 1.6172 1.2461 11.312 2.4961 22.621 3.7422 33.934 0.097657 0.875 0.66406 1.6172 1.6172 1.6172h37.078c2.0898 0.003906 2.0898-3.2344 0.003906-3.2344z",
          "m433.02 480.2h-13.516l1.6172 1.6172c-1.4453-16.703-2.8945-33.402-4.3398-50.105-2.5312-29.227-5.0664-58.457-7.5977-87.684-0.98438-11.355-1.9688-22.715-2.9531-34.07l-1.6172 1.6172h9.4688c-0.53906-0.53906-1.0781-1.0781-1.6172-1.6172 2.3633 21.445 4.7266 42.895 7.0898 64.344 2.1562 19.586 4.3164 39.172 6.4766 58.758 1.7891 16.254 3.582 32.508 5.375 48.762 0.22656 2.0508 3.4648 2.0742 3.2383 0-2.3633-21.441-4.7266-42.887-7.0898-64.328-2.1523-19.531-4.3047-39.066-6.457-58.598-1.7969-16.312-3.5938-32.625-5.3945-48.934-0.097656-0.875-0.66406-1.6172-1.6172-1.6172h-9.4688c-0.82422 0-1.6953 0.73828-1.6172 1.6172 1.4453 16.703 2.8945 33.402 4.3398 50.105 2.5312 29.227 5.0664 58.457 7.5977 87.684 0.98438 11.359 1.9688 22.715 2.9531 34.07 0.078125 0.87891 0.67969 1.6172 1.6172 1.6172h13.516c2.0859-0.003906 2.0859-3.2383-0.003906-3.2383z",
          "m423.67 266.16v8.2109c0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-68.102-26.453c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172v-8.2109c-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h68.102 26.453c2.0859 0 2.0859-3.2383 0-3.2383h-68.102-26.453c-0.88281 0-1.6172 0.73438-1.6172 1.6172v8.2109c0 0.88281 0.73438 1.6172 1.6172 1.6172h68.102 26.453c0.88281 0 1.6172-0.73438 1.6172-1.6172v-8.2109c0.003906-2.082-3.2344-2.082-3.2344 0.003906z",
          "m423.67 283.13v6.0234c0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-68.102-26.453c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172v-6.0234c-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h68.102 26.453c2.0859 0 2.0859-3.2383 0-3.2383h-68.102-26.453c-0.88281 0-1.6172 0.73438-1.6172 1.6172v6.0234c0 0.88281 0.73438 1.6172 1.6172 1.6172h68.102 26.453c0.88281 0 1.6172-0.73438 1.6172-1.6172v-6.0234c0.003906-2.082-3.2344-2.082-3.2344 0.003906z",
          "m421.34 274.37v8.7578l1.6172-1.6172h-68.746-21.148c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172v-8.7578c-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h68.746 21.148c2.0859 0 2.0859-3.2383 0-3.2383h-68.746-21.148c-0.88281 0-1.6172 0.73438-1.6172 1.6172v8.7578c0 0.88281 0.73438 1.6172 1.6172 1.6172h68.746 21.148c0.88281 0 1.6172-0.73438 1.6172-1.6172v-8.7578c0-2.082-3.2344-2.082-3.2344 0.003906z",
          "m421.34 289.15v20.797c0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-68.746-21.148c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172v-20.797c-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h68.746 21.148c2.0859 0 2.0859-3.2383 0-3.2383h-68.746-21.148c-0.88281 0-1.6172 0.73438-1.6172 1.6172v20.797c0 0.88281 0.73438 1.6172 1.6172 1.6172h68.746 21.148c0.88281 0 1.6172-0.73438 1.6172-1.6172v-20.797c0-2.0859-3.2344-2.0859-3.2344 0.003906z",
          "m382.95 294.62v15.328c0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-13.578c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172v-15.328c-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h13.578c2.0859 0 2.0859-3.2383 0-3.2383h-13.578c-0.88281 0-1.6172 0.73438-1.6172 1.6172v15.328c0 0.88281 0.73438 1.6172 1.6172 1.6172h13.578c0.88281 0 1.6172-0.73438 1.6172-1.6172v-15.328c0-2.0859-3.2344-2.0859-3.2344 0.003906z",
          "m364.22 294.62v15.328c0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-7.0195c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172v-15.328c-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h7.0195c2.0859 0 2.0859-3.2383 0-3.2383h-7.0195c-0.88281 0-1.6172 0.73438-1.6172 1.6172v15.328c0 0.88281 0.73438 1.6172 1.6172 1.6172h7.0195c0.88281 0 1.6172-0.73438 1.6172-1.6172v-15.328c0-2.0859-3.2344-2.0859-3.2344 0.003906z",
          "m395.12 294.62v15.328c0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-7.0195c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172v-15.328c-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h7.0195c2.0859 0 2.0859-3.2383 0-3.2383h-7.0195c-0.88281 0-1.6172 0.73438-1.6172 1.6172v15.328c0 0.88281 0.73438 1.6172 1.6172 1.6172h7.0195c0.88281 0 1.6172-0.73438 1.6172-1.6172v-15.328c0.003906-2.0859-3.2344-2.0859-3.2344 0.003906z",
          "m347.6 309.95c-1.3633 16.703-2.7266 33.402-4.0898 50.105-2.3867 29.227-4.7695 58.457-7.1562 87.684-0.92578 11.359-1.8555 22.715-2.7812 34.07 0.53906-0.53906 1.0781-1.0781 1.6172-1.6172h-14.379c0.53906 0.53906 1.0781 1.0781 1.6172 1.6172 2.3633-21.441 4.7266-42.887 7.0898-64.328 2.1523-19.531 4.3047-39.066 6.457-58.598 1.7969-16.312 3.5938-32.625 5.3945-48.934-0.53906 0.53906-1.0781 1.0781-1.6172 1.6172h9.4688c2.0859 0 2.0859-3.2383 0-3.2383h-9.4688c-0.95703 0-1.5234 0.74219-1.6172 1.6172-2.3633 21.445-4.7266 42.895-7.0898 64.344-2.1562 19.586-4.3164 39.172-6.4766 58.758-1.7891 16.254-3.582 32.508-5.375 48.762-0.097656 0.87891 0.80859 1.6172 1.6172 1.6172h14.379c0.9375 0 1.5469-0.73828 1.6172-1.6172 1.3633-16.703 2.7266-33.402 4.0898-50.105 2.3867-29.227 4.7695-58.457 7.1562-87.684 0.92578-11.355 1.8555-22.715 2.7812-34.07 0.17187-2.082-3.0664-2.0664-3.2344 0z",
          "m357.1 329.9h41.359c2.0859 0 2.0859-3.2383 0-3.2383h-41.359c-2.0859 0.003907-2.0859 3.2383 0 3.2383z",
          "m356.01 349.14h43.543c2.0859 0 2.0859-3.2383 0-3.2383h-43.543c-2.0898 0.003906-2.0898 3.2383 0 3.2383z",
          "m355.15 368.38h45.258c2.0859 0 2.0859-3.2383 0-3.2383h-45.258c-2.0859 0-2.0859 3.2383 0 3.2383z",
          "m347.11 464.55h61.336c2.0859 0 2.0859-3.2383 0-3.2383h-61.336c-2.0859 0-2.0859 3.2383 0 3.2383z",
          "m349.22 445.31h57.121c2.0859 0 2.0859-3.2383 0-3.2383h-57.121c-2.0898 0-2.0898 3.2383 0 3.2383z",
          "m350.99 426.08h53.219c2.0859 0 2.0859-3.2383 0-3.2383h-53.219c-2.0898 0-2.0898 3.2383 0 3.2383z",
          "m352.86 406.84h49.473c2.0859 0 2.0859-3.2383 0-3.2383h-49.473c-2.0859 0-2.0859 3.2383 0 3.2383z",
          "m353.44 387.61h48.691c2.0859 0 2.0859-3.2383 0-3.2383h-48.691c-2.0898 0-2.0898 3.2383 0 3.2383z",
          "m333.45 483.68c22.938-0.039063 45.875-0.082032 68.812-0.12109 6.3516-0.011719 12.703-0.023438 19.059-0.035156 2.0859-0.003906 2.0898-3.2422 0-3.2383-22.938 0.039063-45.875 0.082032-68.812 0.12109-6.3516 0.011719-12.707 0.023438-19.059 0.035156-2.0859 0.003907-2.0898 3.2422 0 3.2383z",
        ].map((path, index) => (
          <React.Fragment key={index}>
            <path
              stroke={COLORS.gold}
              strokeWidth="20"
              strokeOpacity="0.05"
              d={path}
            />
            <path
              stroke={COLORS.gold}
              strokeWidth="12"
              strokeOpacity="0.1"
              d={path}
            />
            <path fill={COLORS.dark_gold} d={path} />
          </React.Fragment>
        ))}
      </g>
    </svg>
  );
};
