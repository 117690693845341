import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Logo } from "../components";
import { useStore, LANGUAGE } from "../context/store";
import COLORS from "../data/colors";
import { useLocation } from "@reach/router";
import { Disclaimer } from "./Disclaimer";

const NavBurger = styled.button`
  box-shadow: none !important;
`;

const StyledLink = styled(Link)`
font-family: Cormorant Garamond;
font-size: 18px;
}`;

const Layout = props => {
  const location = useLocation();
  const { language, toggleLanguage, translations } = useStore();
  const { title, children } = props;
  const isHomePage = location.pathname === "/";
  const route = location.pathname;

  const [toggleNav, setToggleNav] = React.useState(false);
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <NavBurger
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(s => !s)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </NavBurger>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li
                className={`${route === "/" ? "nav-current" : ""}`}
                role="menuitem"
                onClick={() => setToggleNav(false)}
              >
                <StyledLink to={`/#services`}>
                  {translations.services}
                </StyledLink>
              </li>
              <li
                className={`${route === "/about" ? "nav-current" : ""}`}
                role="menuitem"
              >
                <StyledLink to={`/about`}>{translations.about}</StyledLink>
              </li>
              <li
                className={`${route === "/contact" ? "nav-current" : ""}`}
                role="menuitem"
              >
                <StyledLink to={`/contact`}>
                  {translations.contact_us}
                </StyledLink>
              </li>
            </ul>
          </nav>
          <div
            className="site-head-center"
            style={{ display: toggleNav ? "none" : "flex" }}
          >
            <StyledLink
              className="site-head-logo"
              to={`/`}
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                opacity: isHomePage ? 0 : 1,
                position: "absolute",
                ...(isHomePage ? { pointerEvents: "none" } : {}),
              }}
            >
              <div
                style={{
                  width: isHomePage ? 80 : 80,
                  height: isHomePage ? 80 : 80,
                }}
              >
                <Logo />
              </div>
              {!isHomePage && (
                <div
                  style={{
                    marginTop: "-4px",
                    color: COLORS.companyName,
                    fontFamily: "Libre Baskerville",
                  }}
                >
                  <div>MAYA</div>
                  <div>SEGUROS Y MAS</div>
                </div>
              )}
            </StyledLink>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.facebook.com/Maya-Seguros-y-Mas-109964308265010"
                title="Maya Seguros y Mas Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <button
                onClick={toggleLanguage}
                style={{ boxShadow: "none", lineHeight: 1 }}
              >
                <div id="language-toggle-btn">
                  {language === LANGUAGE.en ? "Español" : "English"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <Disclaimer />
      <footer
        className="site-foot"
        style={{ textTransform: "uppercase", fontFamily: "Libre Baskerville" }}
      >
        &copy; {new Date().getFullYear()} {title}
      </footer>
    </div>
  );
};

export default Layout;
